import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  startLoader,
  stopLoader,
  ctc_modalOff,
  ctc_modalOn,
} from "../store/reducers/generalActions";
import { contactform, getCountry } from "../services";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Modal,
  CardHeader,
  FormGroup,
  Form,
} from "reactstrap";
import MSG, { notifyError, notifySuccess } from "../utils/Helper";
//import 'react-phone-number-input/style.css'
import "./css/cta.css"
import "react-phone-input-2/lib/style.css";

import PhoneInput from "react-phone-input-2";
class CTA extends Component {
  state = {
    countryCode: 'in',
    open: false,
    phones: "",
    data: {
      cta_name:'contact',
      name: "",
      phone_number: "",
      company_name: "",
      email: "",
      question: "",
    },
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    this.getCountryName()
  }
  getCountryName = async () => {
    // this.props.dispatch(startLoader())
    const {data: res} = await getCountry()
    if(res.success){
      this.setState({countryCode: res.response.country_alpha_2})
    }
    // this.props.dispatch(stopLoader())
  }

  toggle = () => {
    this.setState({
      open: !this.state.open,
      data: {
        name: "",
        phone_number: "",
        company_name: "",
        email: "",
        question: "",
      },
    });
  };

  handlephone = (phone) => {
    this.setState({
      data: {
        ...this.state.data,
        phone_number: phone,
      },
    });
  };

  handleChange = ({ currentTarget: input }) => {
    this.setState({
      data: {
        ...this.state.data,
        [input.name]: input.value,
      },
    });
  };

  handlesubmit = async (e) => {
    ////console.log(this.state.phones)
    ////console.log(this.state.data.phone_number)
    // const re = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
    const re_email = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    /////console.log(re.test(this.state.data.phone_number))
    e.preventDefault();
    const { data } = this.state;
    if (_.isEmpty(data.name)) {
      notifyError("Name is required");
      return false;
    } else if (_.isEmpty(data.company_name)) {
      notifyError("Company Name is required");
      return false;
    } else if (_.isEmpty(data.phone_number) /*|| !re.test(data.phone_number)*/) {
      notifyError("Please enter valid phone number");
      ////console.log("ACHHA")
      return false;
    }

    if (data.phone_number.length > 15) {
      ////console.log("HHHJHH")
      notifyError("Please enter valid phone number.");
      return false;
    }

    if (data.phone_number.length < 6) {
      ////console.log("HHHJHH")
      notifyError("Please enter valid phone number.");
      return false;
    } else if (_.isEmpty(data.email) || !re_email.test(data.email)) {
      notifyError("Please enter valid email");
      return false;
    } else if (_.isEmpty(data.question)) {
      this.setState({
        data: {
          question: "User have not entered any query !",
        },
      });
      // notifyError("Please enter your Query");
      // return false;
    }
    const req = { ...data };
    this.props.dispatch(startLoader());
    try {
      ////console.log(req)
      if (req.question === "") {
        req.question = "User haven't entered any query !";
      }
      const resp = await contactform(req);
      ////console.log(resp.data)
      if (resp.data.code === 200) {
        notifySuccess("Form submitted successfully. We will contact you soon.");
        this.props.dispatch(ctc_modalOff());
      }
      if (resp.data.code === 400) {
        notifyError("Something went wrong, please try again later !");
        this.toggle();
      }
    } catch (err) {
      ////console.log(err);
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  render() {
    return (
      <div>
        {/* <section className="section section-lg pt-0 mt-3">
          <Container>
            <Card className="bg-gradient-default shadow-lg border-0">
              <div className="p-5">
                <Row className="align-items-center">
                  <Col lg="8">
                    <h3 className="text-white">{this.props.title}</h3>
                    <p className="lead text-white mt-3">
                    Talk to our expert to learn more about HireLakeAI API solution and its implementation into your system.
                    </p>
                  </Col>
                  <Col className="ml-lg-auto" lg="3">
                    <Button
                      block
                      className="btn-white"
                      color="default"
                      size="lg"
                      onClick={this.toggle}
                    >
                      Get in touch
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
          </Container>
        </section> */}

        <Modal
          isOpen={this.props.contacts}
          toggle={() => this.props.dispatch(ctc_modalOff())}
          centered
          style={{width: 'fit-content'}}
        >
          <div className="modal-body p-0" style={{ backgroundColor: "white" }}>
            <Card style={{height: "100%", width:"28rem"}} className="bg-secondary shadow border-0">
              <CardHeader
                className=" pb-1"
                style={{ backgroundColor: "white" }}
              >
                <button
                  aria-label="Close"
                  className="close contact"
                  data-dismiss="modal"
                  
                  type="button"
                  onClick={() => this.props.dispatch(ctc_modalOff())}
                >
                  <span style={{fontSize:"2rem", margin: "0.5rem"}} aria-hidden={true}>×</span>
                </button>
                <div style={{
                  fontWeight: "550",
                  fontSize: "2.5rem"
                }} className="text-dark text-center mt-2">
                  Write Us
                </div>
              </CardHeader>
              <CardBody style={{ backgroundColor: "white" }}>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Name"
                        style={{fontSize: "16px"}}
                        type="name"
                        name="name"
                        onChange={this.handleChange}
                        value={this.state.data.name}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Company"
                        style={{fontSize: "16px"}}
                        type="company"
                        name="company_name"
                        onChange={this.handleChange}
                        value={this.state.data.company_name}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="mb-3">
                    {/* <Input
                        placeholder="Phone Number with Country Code"
                        className="place"
                        type="tel"
                        name="phone_number"
                        onChange={this.handleChange}
                        value={this.state.data.phone_number}
                      /> */}
                    <div
                    className="input-group-alternative"
                    
                    >
                      <PhoneInput
                        placeholder="Phone Number with Country Code"
                        className="place "
                        country={_.toLower(this.state.countryCode)}
                        value={this.state.data.phone_number}
                        onChange={(phone) => this.handlephone(phone)}
                        containerStyle={{
                          backgroundColor: "white",
                        }}
                        inputStyle={{
                          flex: 1,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white",
                          fontSize: 16,
                          borderWidth: 0,
                        }}
                        buttonStyle={{
                          width: 40,
                          borderWidth: 0,
                        }}
                      />
                    </div>

                    {/* </InputGroup> */}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="Email"
                        style={{fontSize: "16px"}}
                        type="email"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.data.email}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="How can we help you (optional)"
                        style={{fontSize: "16px"}}
                        type="textarea"
                        name="question"
                        onChange={this.handleChange}
                        value={this.state.data.question}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      color="#2E8AE4"
                      style={{ backgroundColor: "#2E8AE4", color: "white" }}
                      type="button"
                      onClick={this.handlesubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    cta_on: state.generalActions.contact_modal,
  };
};

export default connect(mapStateToProps)(CTA);
