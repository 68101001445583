import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {FaCopy} from "react-icons/fa";
import {Tooltip} from "react-tippy";
import "../../components/css/custom_tooltip.css";

class ClipBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCopied: false,
		};
	}

	handleCopy = () => {
		navigator.clipboard.writeText(this.props.link);
		this.setState({isCopied: true});
		alert("Copied to clipboard!");
		setTimeout(() => this.setState({isCopied: false}), 2000);
	};

	render() {
		return (
			<div className='clipboard'>
				<Tooltip
					position='top'
					html={
						<div className='table-action-hover-tooltip'>
							Copy to ClipBoard
						</div>
					}
				>
					<Button
						// variant='outline-info'
						onClick={() => this.handleCopy()}
						style={{
							color: "#229be9",
							borderColor: "#229be9",
							background: "white",
						}}
					>
						<FaCopy className='copyIcon' />
					</Button>
				</Tooltip>
			</div>
		);
	}
}

export default ClipBoard;
