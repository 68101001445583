import React, {Component} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {Link, withRouter} from "react-router-dom";
import {FaInfoCircle} from "react-icons/fa";
import Avatar from "react-avatar";
import {
	UncontrolledCollapse,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Row,
	Col,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from "reactstrap";
import {authLogout} from "./../store/reducers/auth";
import {FaAngleDown, FaRegUser, FaRegQuestionCircle} from "react-icons/fa";
import {IoDocumentTextOutline} from "react-icons/io5";
import {AiOutlineMenu} from "react-icons/ai";
import {userdetails} from "./../services";
import "../components/css/custom_tooltip.css";
import {ctc_modalOn, api_modalOn} from "../store/reducers/generalActions";
import "./css/header.css";
import _ from "lodash";
import {notifyError} from "../utils/Helper";
import {set_initial} from "../store/reducers/jdmatcherActions";
import {Tooltip} from "react-tippy";
import {RiUserSettingsLine} from "react-icons/ri";

class Header extends Component {
	state = {
		user: "",
		refreshing: true,
		collapseClasses: "",
		collapseOpen: false,
		curr: null,
		username: "",
		loaders: false,
		email: "",
		phone: "",
		isOpen: false,
	};

	componentDidMount() {
		this.setState({
			refreshing: false,
		});

		if (localStorage.getItem("access_token")) {
			////console.log(this.props.username)
			this.setState({
				user: true,
			});

			// this.setUsername(this.props.username.username)
		}

		this.data();
	}

	setloader = load => {
		this.setState({
			loaders: load,
		});
	};

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	truncateString = () => {
		if (!_.isEmpty(this.props.username.username)) {
			const fullName = this.capitalizeLetters(this.props.username.username);
			if (fullName > 10) {
				return fullName.slice(0, 10) + "...";
			} else {
				return fullName;
			}
		}
	};

	truncateEmail = () => {
		const num = 30;
		if (!_.isEmpty(this.props.username.Email)) {
			if (this.props.username.Email.length > num) {
				return this.props.username.Email.slice(0, num) + "...";
			} else {
				return this.props.username.Email;
			}
		}
	};

	data = async () => {
		//console.log(this.props.username.username);
		if (this.props.username.username) {
			const user_name = localStorage.getItem("user_name");
			const user_email = localStorage.getItem("user_email");
			if (!user_name || !user_email) {
				try {
					this.setloader(true);
					const user_data = await userdetails();
					const user_info = user_data.data.response;
					if (user_data.data.success) {
						//console.log(user_data.data);
						localStorage.setItem("user_name", user_info.username);
						localStorage.setItem("user_email", user_info.Email);
						this.setUsername(user_info.username);
						this.setEmail(user_info.Email);

						//console.log(this.state.username);
					} else {
						this.setloader(false);
						notifyError("Something Went Wrong");
					}
				} catch (err) {
					console.log("err");
					////console.log(err.message)
				}
			} else {
				this.setUsername(user_name);
				this.setEmail(user_email);
			}
		}
		this.setloader(false);
	};

	onExiting = () => {
		this.setState({
			collapseClasses: "collapsing-out",
		});
	};

	setUsername = username => {
		this.setState({
			username,
		});
	};
	setEmail = email => {
		this.setState({
			email,
		});
	};

	onExited = () => {
		this.setState({
			collapseClasses: "",
		});
	};

	handleLogout = e => {
		e.preventDefault();
		localStorage.removeItem("user_name");
		localStorage.removeItem("user_email");
		this.props.dispatch(set_initial());
		this.props.dispatch(authLogout());
	};

	capitalizeLetters(name) {
		let fullname = name;
		return _.startCase(fullname);
	}

	render() {
		return (
			<>
				<Helmet>
					<title>
						Advanced Resume Parsing & Job matching powered by AI - Test
						for Free
					</title>
					<meta
						name='description'
						content='Test our AI-Powered HR Solution. Connect with our AI professional to build a custom solution for your use case.'
					/>
				</Helmet>
				<header className='header-global navStyle bg-white'>
					<Navbar
						className='navbar-top navbar-horizontal navbar-light'
						expand='lg'
						style={{height: 70}}
					>
						<div className='container-fluid'>
							<NavbarBrand to='/' tag={Link} style={{width: 152}}>
								<div>
									<img
										alt='...'
										src='/blue-white 2logo.svg'
										className='img-fluid'
									/>
								</div>
							</NavbarBrand>
							<button
								className='navbar-toggler'
								id='navbar-collapse-main'
							>
								<AiOutlineMenu />
							</button>
							<UncontrolledCollapse
								navbar
								toggler='#navbar-collapse-main'
							>
								<div className='navbar-collapse-header'>
									<Row>
										<Col className='collapse-brand' xs='6'>
											<Link to='/'>
												<img alt='...' src='/blue-black.png' />
											</Link>
										</Col>
										<Col className='collapse-close' xs='6'>
											<button
												className='navbar-toggler'
												id='navbar-collapse-main'
											>
												<span />
												<span />
											</button>
										</Col>
									</Row>
								</div>
								<Nav
									style={{
										display: "flex",
										alignItems: "center",
										gap: "7px",
									}}
									className='ml-auto smallNav'
									navbar
								>
									<li className='nav-link-icon mt-1'>
										<div
											className='nav-link-inner--text'
											style={{
												textTransform: "none",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div style={{fontWeight: "bold"}}>
												Remaining Credits :{" "}
												<span
													class='text-capitalize'
													style={{color: "#fd1a1b"}}
												>
													{this.props.username
														?.remaining_app_credits > 0
														? this.props.username
																?.remaining_app_credits
														: "0"}
												</span>{" "}
												<Tooltip
													position='bottom'
													style={{background: "transparent"}}
													title=''
													html={
														<div className='table-action-hover-tooltip'>
															<div>1 CV/JD Parse = 1 credit</div>
															<div>1 Job Match = 1 credit</div>
															<div>1 Resume Format = 3 credit</div>
														</div>
													}
												>
													<FaInfoCircle
														style={{
															margin:
																"0px 0.4rem  0.3rem  0.4rem",
															cursor: "pointer",
															fontSize: "1.2rem",
														}}
													/>
												</Tooltip>
											</div>
										</div>
									</li>
									<li>
										<a
											href='https://calendly.com/hirelakeai/demo-brief-for-hirelakeai'
											target='_blank'
											rel='noreferrer'
											class='elementor-button-link elementor-button schedule-call-btn elementor-size-sm btn'
											role='button'
											style={{textTransform: "none"}}
										>
											{" "}
											<span class='elementor-button-content-wrapper'>
												{" "}
												<span class='elementor-button-text'>
													Schedule a call
												</span>{" "}
											</span>{" "}
										</a>
									</li>
									<NavItem className='py-2'>
										<NavLink
											className='nav-link-icon mt-1 '
											to='/saved-result'
											tag={Link}
										>
											<span
												className='nav-link-inner--text '
												style={{
													fontWeight: 650,
													color: "#000000",
													fontSize: 16,
												}}
											>
												Saved Profiles
											</span>
										</NavLink>
									</NavItem>

									<NavItem>
										<UncontrolledDropdown>
											<DropdownToggle nav>
												<div className='profile'>
													<div className='' style={{width: 30}}>
														{!_.isEmpty(this.props.username) ? (
															<Avatar
																name={this.truncateString()}
																src={
																	!_.isEmpty(
																		this.props.username
																			.profile_pic
																	)
																		? this.props.username
																				.profile_pic
																		: ""
																}
																round={true}
																size={35}
															/>
														) : (
															<>Loading..</>
														)}
													</div>
													<div className=''>
														<span
															style={{
																fontWeight: "bold",
																fontSize: 16,
																color: "#000000",
															}}
														>
															{!_.isEmpty(
																this.props.username
															) ? (
																<>{this.truncateString()}</>
															) : (
																<>Loading..</>
															)}
														</span>
													</div>
													<span
														style={{
															marginLeft: "-8px",
															marginTop: "-3px",
														}}
													>
														<FaAngleDown style={{}} />
													</span>
												</div>
											</DropdownToggle>
											<div className='dropdown-cont'>
												<DropdownMenu
													right
													className='dropdown-popup-custom'
													style={{
														marginLeft: "15px",
														marginTop: "83px",
														minWidth: "10rem !important",
													}}
												>
													<DropdownItem
														href='/profile'
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
													>
														<FaRegUser color='#209ae9' />
														<span>View Profile</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															this.props.dispatch(ctc_modalOn())
														}
													>
														<FaRegQuestionCircle color='#209ae9' />
														<span>Email Support</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															this.props.dispatch(api_modalOn())
														}
													>
														<RiUserSettingsLine color='#209ae9' />
														<span>Get API Credentials</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															window.open(
																"https://documenter.getpostman.com/view/36562682/2sA3drHuNH",
																"_blank"
															)
														}
													>
														<IoDocumentTextOutline color='#209ae9' />
														<span>API Documentation</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={e => this.handleLogout(e)}
													>
														<img
															className='img-fluid'
															src='image/logout.svg'
															alt=''
															style={{marginRight: 15}}
														/>
														<span>Logout</span>
													</DropdownItem>
												</DropdownMenu>
											</div>
											<div className='dropdown-cont-mobile'>
												<DropdownMenu
													right
													style={{
														minWidth: "10rem !important",
													}}
												>
													<DropdownItem
														href='/profile'
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
													>
														<FaRegUser color='#209ae9' />
														<span>View Profile</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															this.props.dispatch(ctc_modalOn())
														}
													>
														<FaRegQuestionCircle color='#209ae9' />
														<span>Email Support</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															this.props.dispatch(api_modalOn())
														}
													>
														<RiUserSettingsLine color='#209ae9' />
														<span>Get API Credentials</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={() =>
															window.open(
																"https://documenter.getpostman.com/view/36562682/2sA3drHuNH",
																"_blank"
															)
														}
													>
														<IoDocumentTextOutline color='#209ae9' />
														<span>API Documentation</span>
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className='dropItems'
														style={{
															textAlign: "left",
															minWidth: "10rem !important",
														}}
														onClick={e => this.handleLogout(e)}
													>
														<img
															className='img-fluid'
															src='image/logout.svg'
															alt=''
															style={{marginRight: 15}}
														/>
														<span>Logout</span>
													</DropdownItem>
												</DropdownMenu>
											</div>
										</UncontrolledDropdown>
									</NavItem>
								</Nav>
							</UncontrolledCollapse>
						</div>
					</Navbar>
				</header>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		username: state.auth.user,
	};
};
export default withRouter(connect(mapStateToProps)(Header));
