import { combineReducers } from "redux";
import auth from "./auth";
import generalActions from "./generalActions";
import jdmatcherActions from "./jdmatcherActions";
import cvparser from "./cvparser"
import jdmaker from "./jdmaker"
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  toastr: toastrReducer,
  auth,
  generalActions,
  jdmatcherActions,
  cvparser,
  jdmaker
});
