import axios from "axios";
import { API_CONNECTION } from "../assets/data/apiConnection";

let API_URL = "";

if(API_CONNECTION === "PROD"){
  API_URL = "https://api.hirelake.ai:9443/"
}
else if (API_CONNECTION === "STAGE"){
  API_URL = "https://stagingapi.hirelake.ai:8443/"
}
else if (API_CONNECTION === "DEV"){
  API_URL = "http://localhost:5000/";
  // API_URL = "http://192.168.1.13:5000/";
}





axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Access-Control-Allow-Origin"] =
  "http://localhost:3000";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = API_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
