const EDIT_JDDETAILS = 'EDIT_JDDETAILS'

export function edit_single(payload){
    return {type:EDIT_JDDETAILS, payload}
  }

const initialState = {
    single_jd_data:'running'
}

export default function reducer(state=initialState,{ type, payload = null }){
    switch (type){
       case EDIT_JDDETAILS:
          return edit_data(state, payload)   
       default:
          return state 
    }
 }

function edit_data(state, payload){  
    return {
       ...state,
       single_jd_data: payload
    }
  }