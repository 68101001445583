import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router-dom";

// import services actions
import LoaderCircle from "./../components/loader-circle";
import Footer from "../components/footer";
import Header from "../components/header";

function PublicRoutes(props) {
  const {
    isAuthenticated,
    refreshing,
    user,
    children,
    dispatch,
    component: Component,
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense fallback={<LoaderCircle isLoading={true} />}>
            <LoaderCircle isLoading={refreshing} />
             
            <Component {...props} />
            
          </Suspense>
        );
      }}
    />
  );
}

PublicRoutes.displayName = "Public Routes";

PublicRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  // user: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    refreshing: state.generalActions.refreshing,
  };
};

export default withRouter(connect(mapStateToProps)(PublicRoutes));
