//import Cookies from 'universal-cookie';
import Http from "./utils/Http";
import { API_CONNECTION } from "./assets/data/apiConnection";
import { setUserData, authLogout } from "./store/reducers/auth";
import { notifyError } from "./utils/Helper";

let stagingUrl = "";
let model_api = "";

if (API_CONNECTION === "STAGE") {
  stagingUrl = "https://stagingapi.hirelake.ai:8443/";
  model_api = "https://stagingapi.hirelake.ai:8443/";
} else if (API_CONNECTION === "PROD") {
  stagingUrl = "https://api.hirelake.ai:9443/";
  model_api = "https://api.hirelake.ai:9443/";
} else if (API_CONNECTION === "DEV") {
  stagingUrl = "http://localhost:5000/";
  // stagingUrl = "http://192.168.1.13:5000/";
  model_api = "http://localhost:5000/";
  // model_api = "http://localhost:5000/";
}

// export function logoutUser(params) {
//   return Http.post(stagingUrl + "logout-user", params, {
//     headers: {
//       "content-type": "text/html",
//     },
//   });
// }
export function getCountry() {
  return Http.get("get-country");
}

export function deleteCommEntry(params) {
  return Http.post("delete-comm-entry", params);
}

export function generateStatement() {
  return Http.get("generate-problem-statement");
}

export function commCheckerEmail(params) {
  return Http.post("communication-checker-email", params);
}

export function fetch_communication_skill_email() {
  return Http.get("fetch-communication-skills-email");
}

export function fetch_candidate_details(params) {
  return Http.post("fetch-candidate-details", params);
}

export function generate_comm_email_result(params) {
  return Http.post("communication-email-results", params);
}

export function fetch_communication_skill_video() {
  return Http.get("fetch-communication-skills-video");
}

export function fetch_communication_skill_video_download_excel(params) {
  return Http.post("download-communication-excel-fetched", params, {
    responseType: "arraybuffer",
  });
}

export function fetch_communication_skill_email_download_excel(params) {
  return Http.post("download-communication-excel-email", params, {
    responseType: "arraybuffer",
  });
}

// JDMAKER SECTION STARTS

export function jdMakerS1(params) {
  return Http.post("jd-maker-s1", params);
}

export function jdMakerS2(params) {
  return Http.post("jd-maker-s2", params);
}

export function fetchJdDetails(params) {
  return Http.post("fetch-jddetails", params);
}

export function fetchAllJds() {
  return Http.get("fetch-alljds");
}

export function deleteJd(params) {
  return Http.post("delete-jd", params);
}

export function feedBack(params) {
  return Http.post("feedback", params);
}
export function check_feedback_status(params) {
  return Http.get("feedback-checker/" + params);
}
// JDMKAER SECTION ENDS

export function logoutUser(params) {
  return Http.post("logout-user", params);
}

export function checkshortlist(params) {
  return Http.get(stagingUrl + "shortlisted-res");
}

// export function registerUser(params) {
//   return Http.post(stagingUrl + "register-user", params, {
//     headers: {
//       "content-type": "application/json",
//       'Access-Control-Allow-Origin': '*'
//     },
//   });
// }

export function registerUser(params) {
  return Http.post("register-user", params);
}

// export function loginUser(params) {
//   return Http.post(stagingUrl + "login-user", params, {
//     mode: 'no-cors',
//     headers: {
//       "content-type": "application/json",
//       'Access-Control-Allow-Origin': '*'

//     },
//   });
// }

export function loginUser(params) {
  return Http.post("login-user", params);
}

export function completeProfile(params) {
  return Http.post("complete-profile", params);
}
// export function get_current_user() {
//   return Http.get(stagingUrl + "me", {
//     headers: {
//       "Authorization": `Bearer ${cookies.get('user_id')}`,
//     }
//   });
// }

export function uploadFile(params) {
  return Http.post("", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function resume_extraction_gdrive(params) {
  return Http.post("cv-parser", params);
}

export function fetchAllCvs(params) {
  return Http.post("fetch-all-cvs", params);
}

export function deleteCv(params) {
  return Http.post("delete-cv", params);
}

export function resume_extraction(params) {
  return Http.post(model_api + "cv-parser", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function video_extraction(params) {
  return Http.post(model_api + "test", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function jd_matcher_video_extraction(params) {
  return Http.post(model_api + "jd-video", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function communication_video_extraction(params) {
  return Http.post(
    model_api + "communication_skill-checker",
    // "http://18.116.158.113:443/communication_skill-checker",
    params,
    {
      headers: {
        "content-type": "multipart/form-data",
      },
    }
  );
}

export function fetch_jdmatcher_results(params) {
  return Http.post(stagingUrl + "fetch-jdmatcher-result", params);
}

export function fetch_jdmatcher_result_s2(params) {
  return Http.post(stagingUrl + "fetch-jdmatcher-result-s2", params);
}

export function extract_jd_info(params) {
  return Http.post("extract-jd-info", params);
}

export function save_editted_jd(params) {
  return Http.post("save-editted-jd", params);
}

export function add_webhook_url(params) {
  return Http.post("add-webhook-url", params);
}

export function jdmatcher(params) {
  return Http.post(model_api + "jd-matcher", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function deleteJdMatcherEntry(params) {
  return Http.get(stagingUrl + "delete-jdmatcher-entry/" + params);
}

export function downloadexcel(params) {
  return Http.post(model_api + "getCSVFile", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
    responseType: "arraybuffer",
  });
}

export function downloadexcelVideo(params) {
  return Http.post(model_api + "download-video-excel", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
    responseType: "arraybuffer",
  });
}

export function downloadexcelVideoCommunication(params) {
  return Http.post(model_api + "download-communication-excel", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
    responseType: "arraybuffer",
  });
}

export function contactform(params) {
  return Http.post("contact", params);
}

export function reverify(params) {
  return Http.post("reverify", params);
}

export function forgotpass(params) {
  return Http.post(stagingUrl + "forgot", params);
}

export function profileupdate(params) {
  return Http.post(stagingUrl + "profile", params);
}

export function userdetails(params) {
  return Http.get(stagingUrl + "userdetails");
}

export function passupdate(params) {
  return Http.post(stagingUrl + "reset/pass", params);
}

export function useractivity(params) {
  return Http.post(stagingUrl + "counter", params);
}

export function verifytoken(params) {
  return Http.get(stagingUrl + "verify/" + params);
}

export function saved_results(params) {
  return Http.post(stagingUrl + "fetch-result", params);
}

export function fetch_cv_filters() {
  return Http.get("fetch-cv-filters");
}

export function fetch_saved_filters(params) {
  return Http.post("fetch-cv-filters", params);
}

export function delete_result(params) {
  return Http.post(stagingUrl + "remove-entry", params);
}

export function saving_results(params) {
  return Http.post(stagingUrl + "save-result", params);
}

export function saving_results_jd_matcher(params) {
  return Http.post(stagingUrl + "save-result-jd", params);
}

export function fetchUser() {
  return async (dispatch) => {
    return await Http.get("userdetails")
      .then((res) => {
        const data = res.data.response;
        ////console.log(data);
        dispatch(setUserData(data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
      });
  };
}

// API CREDENTIALS

export function deleteApp(params) {
  return Http.post("delete-app", params);
}

export function createApp(params) {
  return Http.post("create-app", params);
}

export function refreshAppSecret(params) {
  return Http.post("refresh-app-secret", params);
}

// RESUME FORMATTER

export function resumeExplorer(params) {
  return Http.post("/resume-formatter/status", params);
}

export function deleteRequest(params) {
  return Http.post("/resume-formatter/delete", params);
}

export function fetchParsedResume(params) {
  return Http.post("/resume-formatter/status", params);
}

export function saveRequestInfo(params) {
  return Http.post("/resume-formatter", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function downloadFormattedResume(params) {
  return Http.post("/resume-formatter/download", params, {
    responseType: "blob",
  });
}
