import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {
  startLoader,
  stopLoader,
  api_modalOff,
} from "../store/reducers/generalActions";
import { contactform, userdetails } from "../services";
import {
  Card,
  CardBody,
  Button,
  Input,
  Modal,
  CardHeader,
  FormGroup,
  Form,
} from "reactstrap";
import MSG, { notifyError, notifySuccess } from "../utils/Helper";
import ReactSelect, { components } from "react-select";
import "./css/cta.css";

const Option = props => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type='checkbox'
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

class ApiAccess extends Component {
	state = {
		open: false,
		data: {
			cta_name: "api_access",
			name: "",
			phone_number: "",
			email: "",
			question: "",
		},
		moduleName: [],
		finalModuleNames: [],
		moduleName_message: null,
		hits: "",
		hits_message: "",
		hits_option: [
			{value: "1-1000", label: "1-1000"},
			{value: "1001-10000", label: "1001-10000"},
			{value: "10000+", label: "10000+"},
		],
		module_option: [
			// { value: "JD Maker", label: "JD Maker" },
			{value: "CV/Resume Parser", label: "Resume Parser"},
			{value: "JD Matcher", label: "JD Matcher"},
			// {
			//   value: "Communication skills checker- Video",
			//   label: "Communication skills checker- Video",
			// },
			// {
			//   value: "Communication skills checker- Problem Statement",
			//   label: "Communication skills checker- Problem Statement",
			// },
		],
		customStyles: {
			option: (provided, state) => ({
				...provided,
			}),

			container: base => ({
				...base,

				border: this.state.hits_message ? "1px solid red" : "",
				borderRadius: 5,
			}),

      menu: (provided, state) => ({
        ...provided,
      }),
      placeholder: (base) => ({
        ...base,
        fontSize: 13,
        color: "#78838f",
      }),
    },
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.userData();
  }
 

	toggle = () => {
		this.setState({
			open: !this.state.open,
			data: {
				name: "",
				email: "",
				question: "",
			},
		});
	};
	userData = async () => {
		const user_data = await userdetails();
		if (user_data.data.success) {
			const {username, Email} = user_data.data.response;
			this.setState({
				data: {
					...this.state.data,
					["name"]: username,
					["email"]: Email,
				},
			});
		}
	};
	// handlephone = (phone) => {
	//   this.setState({
	//     data: {
	//       ...this.state.data,
	//       phone_number: phone,
	//     },
	//   });
	// };

	handleChange = ({currentTarget: input}) => {
		this.setState({
			data: {
				...this.state.data,
				[input.name]: input.value,
			},
			success_message: null,
		});
	};

	setmoduleName = module => {
		const finalModuleNames = module.map(el => el.value);
		this.setState({
			moduleName: module,
			finalModuleNames,
			moduleName_message: null,
			success_message: null,
		});
	};

	setHits = hits => {
		this.setState({
			hits: hits,
			hits_message: null,
			success_message: null,
		});
	};

	checkAll = () => {
		const {data} = this.state;
		let ret = true;
		if (_.isEmpty(data.name)) {
			this.setState({
				data: {
					...this.state.data,
					["name"]: "N/A",
				},
			});
		}
		if (_.isEmpty(data.email)) {
			notifyError("Email is required");
			ret = false;
		} else if (_.isEmpty(this.state.finalModuleNames)) {
			notifyError("Module Name is required");
			ret = false;
		} else if (_.isEmpty(this.state.hits)) {
			notifyError("Hits Count is required");
			ret = false;
		}
		return ret;
	};

	handlesubmit = async e => {
		////console.log(this.state.phones)
		////console.log(this.state.data.phone_number)
		// const re = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
		const re_email = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
		/////console.log(re.test(this.state.data.phone_number))
		e.preventDefault();
		this.props.dispatch(startLoader());
		await this.userData();
		if (!this.checkAll()) {
			this.props.dispatch(stopLoader());
			return false;
		}
		const {data} = this.state;
		// console.log(data)
		const req = {
			...data,
			hits: this.state.hits,
			finalModuleNames: this.state.finalModuleNames,
		};
		// console.log(req)
		this.props.dispatch(startLoader());
		try {
			////console.log(req)
			if (req.question === "") {
				req.question = "User haven't entered any query !";
			}
			const resp = await contactform(req);
			////console.log(resp.data)
			if (resp.data.code === 200) {
				notifySuccess(
					"Form submitted successfully. We will contact you soon."
				);
				this.setState({
					success_message:
						"Request for free API access received. Our representative will get in touch soon.",
				});
				// this.props.dispatch(api_modalOff());
			}
			if (resp.data.code === 400) {
				notifyError("Something went wrong, please try again later !");
				this.toggle();
			}
		} catch (err) {
			////console.log(err);
			notifyError(MSG.commonApiError);
		}
		this.props.dispatch(stopLoader());
	};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.contacts}
          centered
          style={{ width: "fit-content" }}
        >
          <div className="modal-body p-0" style={{ backgroundColor: "white" }}>
            <Card
              style={{ height: "100%", width: "28rem" }}
              className="bg-secondary shadow border-0"
            >
              <CardHeader
                className=" pb-1"
                style={{ backgroundColor: "white" }}
              >
                <button
                  aria-label="Close"
                  className="close contact"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.props.dispatch(api_modalOff())}
                >
                  <span style={{ fontSize: "2rem", margin: "0.5rem" }} aria-hidden={true}>
                    ×
                  </span>
                </button>
                <div
                  style={{
                    fontWeight: "550",
                    fontSize: "2rem",
                  }}
                  className="text-dark text-center mt-2"
                >
                  Get Free API Key
                </div>
              </CardHeader>
              <CardBody style={{ backgroundColor: "white" }}>
                <Form role="form">
                
                  <FormGroup className="">
                   
                    <div className=" mt-2">
                   
                      <div className=" text-left">
                        <ReactSelect
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{
                            Option,
                          }}
                          isClearable
                          options={this.state.module_option}
                          type="text"
                          placeholder={
                            this.state.moduleName.length !== 0
                              ? this.state.moduleName
                              : "Select Modules."
                          }
                          id="selectModule"
                          onChange={this.setmoduleName}
                          value={this.state.moduleName}
                          styles={this.state.customStyles}
                        />
                        {this.state.moduleName_message !== null ? (
                          <div className="text-center">
                            <p style={{ color: "red", textAlign: "left" }}>
                              {this.state.moduleName_message}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className=" mt-2">
                     
                      <div className=" text-left">
                        <ReactSelect
                          isClearable
                          options={this.state.hits_option}
                          type="text"
                          placeholder={
                            this.state.hits
                              ? this.state.hits
                              : "Required API Hits (Per Month)"
                          }
                          id="statichits"
                          onChange={(e) => {
                            this.setHits(e.value);
                          }}
                          value={this.state.hits}
                          styles={this.state.customStyles}
                        />
                        {this.state.hits_message !== null ? (
                          <div className="text-center">
                            <p style={{ color: "red", textAlign: "left" }}>
                              {this.state.hits_message}
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                  </FormGroup>

                  <FormGroup className="mb-3">
                      <Input
                        placeholder="Any other specific requirements? (optional)"
                        className="api_input"
                        type="textarea"
                        name="question"
                        onChange={this.handleChange}
                        value={this.state.data.question}
                      />
                    {/* </InputGroup> */}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="mt-3"
                      color="#2E8AE4"
                      style={{ backgroundColor: "#2E8AE4", color: "white" }}
                      type="button"
                      onClick={this.handlesubmit}
                    >
                      Submit
                    </Button>
                  </div>
                  {this.state.success_message && (
                    <span className="success-message-cta">
                      {this.state.success_message}
                    </span>
                  )}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.isAuthenticated,
		user: state.auth.user,
		cta_on: state.generalActions.contact_modal,
	};
};

export default connect(mapStateToProps)(ApiAccess);
