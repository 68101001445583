

const UPDATE_CVPARSER = "UPDATE_CVPARSER"
const REMOVE_CVPARSER = "REMOVE_CVPARSER"
const STORE_DATA = "STORE_DATA"

const UPDATE_SINGLE = "UPDATE_SINGLE"

const UPDATE_SKILL = "UPDATE_SKILL"

export function update_cvparser(payload){

  return {type:UPDATE_CVPARSER,payload}
}

export function remove_cvparser(){

  return {type:REMOVE_CVPARSER}
}

export function store_cvparser(){

   return {type:STORE_DATA}
 }

 export function update_single(payload){

   return {type:UPDATE_SINGLE,payload}
 }

 export function update_skill(payload){

   return {type:UPDATE_SKILL,payload}
 }
 


const initialState = {
    cvparser_data:[],
    singleResult:{},
    skills:[]
}

export default function reducer(state=initialState,{ type, payload = null }){

   switch (type){
      case UPDATE_CVPARSER:
         return add_data(state,payload)
      case STORE_DATA:
         return store_data(state,payload)   
      case REMOVE_CVPARSER:
         return remove_data(state)   
      case UPDATE_SINGLE:
         return updateSingle(state,payload)  
      case UPDATE_SKILL:
         return updateskill(state,payload)    
      default:
         return state   
     
   }
    
   
}

function updateSingle(state,payload){
   return {
      ...state,
      singleResult:payload
   }
}


function updateskill(state,payload){
   return {
      ...state,
       skills:payload
   }
}

function add_data(state,payload){
   
  return {
     ...state,
     cvparser_data:payload
  }
  


}

function store_data(state,payload){
  
      sessionStorage.setItem("cv-result",JSON.stringify(payload))
   
  
   
   
   return {
      ...state,
      cvparser_data:payload
   }
 
 
 }
 


function remove_data(state){
   
  return {
     initialState
  }


}